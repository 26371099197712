
$(() => {
    var dwidth = $(window).width();
    $(window).on('load resize', function () {
        let windowWidth = window.innerWidth;
        if(dwidth!==windowWidth){
            if (windowWidth <= 767) {
                $('.messages_instagram').addClass('d-none')
                $('.profile_instagram').addClass('d-none')
            }
            else {
                $('.messages_instagram').removeClass('d-none')
                $('.profile_instagram').removeClass('d-none')
                $('.list_users').removeClass('d-none');
            }
        }
    });
    $(".list-message").scroll(function () {
        if ($.trim($(this).html().length) > 0) {
            scrollMessage($(this))
        }
    });
    if (window.innerWidth < 989) {
        $('.username_avatar').on('click', function (e) {
            $('.profile_instagram').addClass('d-none');
            $('.list_users').addClass('d-none');
            $('.username_clients').addClass('hover')
            get_user_info($(this).closest('.conversation-client').data('client-id'))
        });
        $(".user-list").click(function () {
            $('.profile_instagram').addClass('d-none');
            $('.list_users').removeClass('d-none');
            $('.username_clients').removeClass('hover')
        });
        $('.username_clients').click(function (e) {
            $('.profile_instagram').addClass('d-none');
            $('.list_users').addClass('d-none');
            if (!($(this).hasClass('hover'))) {
                $(this).addClass('hover')
                let id = $(this).attr('id');
                let value = $(this).attr('value');
                $('#id_hidden_field').val(id);
                get_list_message(id, value)
            }
        });
        $(".user-list-mess").click(function () {
            $('.list_users').removeClass('d-none');
            $('.messages_instagram').addClass('d-none');
            $('.username_clients').removeClass('hover')
        });
    } else {
        $('.conversation-client').click(function (e) {
            $('.conversation-client').not(this).removeClass('hover')
            if (!($(this).hasClass('hover'))) {
                $(this).addClass('hover')
                get_list_message($(this).data('client-id'), $(this).data('conversation-id'))
                get_user_info($(this).data('client-id'))
            }
        });
    }

    let first = $('.border-top')[0]

    if($('.clients_instagram').data('client-id')) {
        $(".border-top[data-client-id=\'" + $('.clients_instagram').data('client-id') + "\']").trigger('click');
    } else {
        $(first).trigger('click');
    }
    let disable = false;

    $('.icon-send-mess').click(function (e) {
        if (!disable) {
            let input = $('.input-mess').val();
            if (input === "") {
                e.preventDefault();
            }

            disable = true;

            setTimeout(function () {
                disable = false;
            }, 1000);
        } else {
            e.preventDefault();
        }
    });
    $('.link_instagram').click(function () {
        let instagram_username = $('.link_instagram').text();
        window.open('https://www.' + instagram_username, '_blank');
    });
});

function get_list_message(id, conversation_id) {
    $('.list-message').empty()
    $('.messages_instagram').removeClass('d-none');
    $('.spinner-container').show();
    ajaxGetMessage(id, conversation_id, null, false).done(function (response) {
        appendMessage(response, conversation_id, id)
        scrollToBottom();
        $('.spinner-container').hide();
    })
}

function scrollMessage(elementScroll) {
    if (elementScroll.scrollTop() === 0) {
        let lastScrollHeight = elementScroll.prop("scrollHeight")
        $('.spinner-container').show();
        let nextPageValue = $('input[name="next-page"]').val();
        let id = $('input[name="next-page"]').attr('id');
        let value = $('input[name="next-page"]').attr('class');
        ajaxGetMessage(id, value, nextPageValue, true).done(function (response) {
            appendMessage(response, value, id)
            $('.spinner-container').hide();
            let scrollDiff = elementScroll.prop("scrollHeight") - lastScrollHeight;
            elementScroll.scrollTop(scrollDiff);
        })
    } else {
        $('.spinner-container').hide();
    }
}
var currentAjaxRequest;

function ajaxGetMessage(client_id, conversation_id, next_page, is_scroll) {
    currentAjaxRequest = $.ajax({
        url: "/instagrams/get_list_messages",
        method: "GET",
        dataType: "json",
        beforeSend : function()    {
            if(currentAjaxRequest && currentAjaxRequest.readyState !== 4) {
                currentAjaxRequest.abort();
            }
        },
        data: {next_page: next_page, id: client_id, conversation_id: conversation_id, is_scroll},
    });
    return currentAjaxRequest;
}

function convertLastTimeMessage(lastTimeMessage) {
    if (!lastTimeMessage) return;

    const lastMessageDate = new Date(lastTimeMessage);
    const currentTime = new Date();

    const timeDifference = (currentTime - lastMessageDate) / 1000;

    if (timeDifference < 60 * 60) {
      return `${Math.floor(timeDifference / 60)} m`;
    } else if (timeDifference < 24 * 60 * 60) {
      return `${Math.floor(timeDifference / (60 * 60))} h`;
    } else if (timeDifference < 30 * 24 * 60 * 60) {
      return `${Math.floor(timeDifference / (24 * 60 * 60))} d`;
    } else {
      return `${lastMessageDate.getDate().toString().padStart(2, '0')}/${(lastMessageDate.getMonth() + 1).toString().padStart(2, '0')}/${lastMessageDate.getFullYear()}`;
    }
  }

function appendMessage(response, conversation_id, client_id) {
    let data = response["data"]["messages"] || response["data"]
    let instagram_id = response["id"]
    let avatar = response["avatar"] || "/avatar_default.png"
    let next_page = data["paging"] ? data["paging"]["cursors"]["after"] : null;
    let messagesData = data["data"].sort(function (a, b) {
        return b["created_time"] - a["created_time"];
    });
    if (response["last_message"]) {
        const client = $(`p#last-message-${client_id}`);
        const clientTime = client.closest('.client-item').find('div.clients-time');
        client.html(response["last_message"]);
        clientTime.html(convertLastTimeMessage(response["last_time_message"]));
    }
    messagesData.forEach(function (message) {
        let sender_id = message["from"]["id"];
        let sender_message = message["message"];
        let sender_insta = $('<div class="text-insta-mess ">').text(sender_message);
        let receiver_insta = $('<div class="text-insta-me">').text(sender_message);
        if(message["is_unsupported"]) {
            sender_insta = $('<div class="text-insta-mess unsupported">').html('サポートされていないメッセージ <span class="note">このメッセージを表示するには Instagram を使用してください</span>');
            receiver_insta = $('<div class="text-insta-me unsupported">').html('サポートされていないメッセージ <span class="note">このメッセージを表示するには Instagram を使用してください</span>');
        }
        let date = message["created_time"];
        if (sender_message === "" && message["attachments"]) {
            let messageContainer, sender_img, html_attachments;
            if ( message["attachments"]["data"][0]["image_data"]){
                sender_img = message["attachments"]["data"][0]["image_data"]["url"];
                html_attachments = '<img src="' + sender_img + '" width="200px" height="200px" style="object-fit:contain;">'
            }
            else if (message["attachments"]["data"][0]["video_data"]) {
                console.log(message)
                sender_img = message["attachments"]["data"][0]["video_data"]["url"];
                html_attachments = '<video src="' + sender_img + '" width="200px" height="200px" style="object-fit:contain;"  controls ></video>'
            }
            if (sender_id === instagram_id) {
                messageContainer = $('<div class="sender-message d-flex">').prepend(
                    '<div class="d-flex align-items-center">' +
                    '<img src="' + avatar + '" width="26px" height="26px" style="border-radius: 100px; border: 0.50px #656565 solid">' +
                    '</div>' +
                    '<div class="sender-insta">' + html_attachments + '</div>'
                );
            } else {
                messageContainer = $('<div class="receiver-message d-flex justify-content-end">').prepend(
                    '<div class="receiver-insta">'  + html_attachments + '</div>'
                );
            }
            $('.list-message').prepend(messageContainer);
        } else {
            let messageContainer;
            if (sender_id === instagram_id) {
                messageContainer = $('<div class="sender-message d-flex">').prepend(
                    '<div class="d-flex align-items-center">' +
                    '<img src="' + avatar + '" width="26px" height="26px" style="border-radius: 100px; border: 0.50px #656565 solid">' +
                    '</div>' +
                    '<div class="sender-insta"></div>'
                );
                messageContainer.find('.sender-insta').prepend(sender_insta);
            } else {
                messageContainer = $('<div class="receiver-message d-flex justify-content-end">').prepend(
                    '<div class="receiver-insta"></div>'
                );
                messageContainer.find('.receiver-insta').prepend(receiver_insta);
            }
            $('.list-message').prepend(messageContainer);
        }
    });
    $('.list-message').prepend('<input type="hidden" name="next-page" value="' + next_page + '" id="' + client_id + '" class="' + conversation_id
        + '">');
}

function get_user_info(client_id) {
    $('#id_hidden_field').val(client_id);
    $.ajax({
        url: "/instagrams/" + client_id,
        method: "GET",
        dataType: "json",
        success: function (response) {
            $('.profile_instagram').removeClass('d-none');
            let avatar = response["avatar"] || "/avatar_default.png";
            let start_time_message = response["data"]["start_time_message"];
            let instagram_username = response["data"]["instagram_username"];
            let last_time_message = response["data"]["last_time_message"];
            let instagram_name = "instagram.com/" + instagram_username;
            let classTag = ['item-instagram-success', 'item-instagram-danger']
            $('.client-avatar').on("error",function() {
                $(this).attr('src', '/avatar_default.png');
            });
            $('.client-avatar').attr('src', avatar);
            $('.instagram_username').text(instagram_username);
            $('.start_time_message').text(moment(start_time_message).format('YYYY-MM-DD HH:mm'));
            $('.last_time_message').text(moment(last_time_message).format('YYYY-MM-DD HH:mm'));
            $('.link_instagram').text(instagram_name);
            $('.tag-instagram').empty()
            $.each(response["data"]['name_tags'],function (index, tag) {
                $('.tag-instagram').append(`<div class='${classTag[index % classTag.length]} mr-2'>${tag}</div>`)
            })
        },
        error: function (xhr) {
        },
    });
}

function scrollToBottom() {
    let container = $('.list-message');
    container.scrollTop(container.prop("scrollHeight"));
    $("html, body").animate({ scrollTop: $(document).height() }, 1000);
}